<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <base-material-card :title="$route.name" icon="mdi-apple-finder">
          <v-row>
            <v-col cols="12" class="pb-0">
              <v-radio-group v-model="radioOption" row>
                <v-radio
                  v-for="item in radioItems"
                  :key="item.title"
                  :label="item.title"
                  :value="item.title"
                />
              </v-radio-group>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <TableCRUD :headers="headers" />
            </v-col>
          </v-row>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "PaymentRecord",
  components: {
    TableCRUD: () => import("@/components/tableCRUD"),
  },
  data() {
    return {
      radioOption: "All",
      radioItems: [
        { title: this.$vuetify.lang.t("$vuetify.categoryListAll") },
        { title: this.$vuetify.lang.t("$vuetify.Unpaid1") },
        { title: this.$vuetify.lang.t("$vuetify.paid") },
        { title: this.$vuetify.lang.t("$vuetify.canceled") },
      ],
      headers: [
        { text: this.$vuetify.lang.t("$vuetify.itemNo") },
        { text: this.$vuetify.lang.t("$vuetify.Supplier") },
        { text: this.$vuetify.lang.t("$vuetify.TotalAmount") },
        { text: this.$vuetify.lang.t("$vuetify.currency") },
        { text: this.$vuetify.lang.t("$vuetify.status") },
        { text: this.$vuetify.lang.t("$vuetify.applicant") },
        { text: this.$vuetify.lang.t("$vuetify.paymentterm") },
        { text: this.$vuetify.lang.t("$vuetify.applyDate") },
        { text: this.$vuetify.lang.t("$vuetify.note") },
        {
          text: this.$vuetify.lang.t("$vuetify.opt"),
          value: "actions",
          sortable: false,
          align: "center",
          width: "80",
        },
      ],
    };
  },
};
</script>

<style></style>
